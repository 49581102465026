<template>
  <main class="eLearning">
    <ELearningBanner v-if="showBanner" />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
import ELearningBanner from '@/components/ELearning/ELearningBanner'

export default {
  components: {
    ELearningBanner
  },
  data() {
    return { showBanner: true }
  },
  watch: {
    $route(to, from) {
      this.setShowBanner()
    }
  },
  mounted() {
    this.setShowBanner()
  },
  methods: {
    setShowBanner() {
      const routeName = this.$route.name
      this.showBanner = routeName !== 'ELearningDetail'
    }
  }
}
</script>

<style lang="scss"></style>
