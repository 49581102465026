<template>
  <div class="baseSelect">
    <select
      id="baseSelect"
      :value="typeof value === 'string' ? value : value.value"
      @change="onChange"
    >
      <option
        v-for="option in options"
        :key="option.text || option"
        :value="typeof option === 'string' ? option : option.value"
        >{{ option.text || option }}</option
      >
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: '1'
    },
    options: {
      type: Array,
      default: () => ['1', '2']
    }
  },
  methods: {
    onChange(e) {
      if (typeof this.value === 'string') {
        this.$emit('input', e.target.value)
        return
      }

      const newValue = this.options.find(
        option => option.value === e.target.value
      )

      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss">
.baseSelect {
  & select {
    width: 100%;
    height: 5rem;
    padding: 1.2rem 2rem;
    border: 1px solid #d0a805;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('~@/assets/image/common/BaseSelect.jpg');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
}
</style>
