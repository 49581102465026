<template>
  <section class="eLearningBanner">
    <img
      src="@/assets/image/ELearning/ELearningLeftBanner.png"
      alt=""
      class="eLearningBanner__leftImage"
    />
    <img
      src="@/assets/image/ELearning/ELearningRightBanner.png"
      alt=""
      class="eLearningBanner__rightImage"
    />

    <div class="eLearningBanner__textContainer">
      <h2 class="eLearningBanner__title" :style="titleImageStyle">
        {{ title }}
      </h2>
      <div class="eLearningBanner__selectContainer">
        <!-- v-if="showSelect" -->
        <BaseSelect
          v-if="false"
          v-model="currentSelectFilter"
          @input="onChangeSelectFilter"
          :options="selectFilterLists"
        />
      </div>
      <div class="eLearningBanner__buttonContainer">
        <router-link
          v-for="(button, index) in buttonLists"
          :key="index"
          class="eLearningBanner__button clickable"
          :style="`background-image: url(${button.image});`"
          :alt="`點擊前往數位學習-${button.text}`"
          :to="{ name: button.routeName }"
        >
          {{ button.text }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue'

export default {
  components: {
    BaseSelect
  },
  data() {
    return {
      titleImageTable: {
        ELearningElementary: require('@/assets/image/ELearning/ELearningTitleElementary.png'),
        ELearningJunior: require('@/assets/image/ELearning/ELearningTitleJunior.png'),
        ELearningMine: require('@/assets/image/ELearning/ELearningTitleMine.png')
      },
      titleTable: {
        ELearningElementary: '國小',
        ELearningJunior: '國中',
        ELearningMine: '礦山講堂',
        ELearningDetail: '數位學習詳細'
      },
      currentSelectFilter: {
        text: '年份不拘',
        value: ''
      },
      showSelect: true
    }
  },
  computed: {
    title() {
      const routeName = this.$route.name
      const title = this.titleTable[routeName]
      if (!title) return ''
      return title
    },
    titleImageStyle() {
      const routeName = this.$route.name
      const titleImage = this.titleImageTable[routeName]
      if (!titleImage) return ''
      if (routeName === 'ELearningMine') {
        return `background-image: url(${titleImage}); width: 14.5rem;`
      }
      return `background-image: url(${titleImage});`
    },
    buttonLists() {
      const routeName = this.$route.name
      const elementary = {
        text: '國小',
        image: require('@/assets/image/ELearning/ELearningButtonElementary.png'),
        routeName: 'ELearningElementary'
      }
      const junior = {
        text: '國中',
        image: require('@/assets/image/ELearning/ELearningButtonJunior.png'),
        routeName: 'ELearningJunior'
      }
      const mine = {
        text: '礦山講堂',
        image: require('@/assets/image/ELearning/ELearningButtonMine.png'),
        routeName: 'ELearningMine'
      }

      if (routeName === 'ELearningElementary') {
        return [junior, mine]
      }

      if (routeName === 'ELearningJunior') {
        return [elementary, mine]
      }

      if (routeName === 'ELearningMine') {
        return [elementary, junior]
      }

      return []
    },
    selectFilterLists() {
      // const allOption = { text: '年份不拘', value: '' }
      if (!this.$store.state.eLearning) return []
      const yearLists = this.$store.state.eLearning.map(
        eLearning => eLearning.year
      )
      const yearSet = new Set([...yearLists])
      const resultLists = [{ text: '年份不拘', value: '' }]
      yearSet.forEach(year => resultLists.push({ text: year, value: year }))
      return resultLists
    }
  },
  watch: {
    $route(to, from) {
      this.$store.commit('setELearningYear', '')
      this.setShowSelect()
    }
  },
  mounted() {
    this.setShowSelect()
  },
  methods: {
    setShowSelect() {
      const routeName = this.$route.name
      this.showSelect = routeName !== 'ELearningMine'
    },
    onChangeSelectFilter(year) {
      this.$store.commit('setELearningYear', year.value)
    }
  }
}
</script>

<style lang="scss">
.eLearningBanner {
  background-image: url('~@/assets/image/common/GrayBanner.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 6.6rem 0;
  position: relative;

  &__leftImage {
    position: absolute;
    left: 4rem;
    bottom: 0;
    width: 23.95rem;
    height: 14.7rem;
    object-fit: contain;
  }

  &__rightImage {
    position: absolute;
    right: 4rem;
    bottom: 0;
    width: 19.7rem;
    height: 4.35rem;
    object-fit: contain;
  }

  &__textContainer {
    width: 1280px;
    max-width: 95%;
    margin: 0 auto;
    padding-left: 25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__selectContainer {
    width: 18rem;
    flex-shrink: 0;
  }

  &__title {
    flex-shrink: 0;
    background-image: url('~@/assets/image/Exhibit/Normal/ExhibitNormalTitle.png');
    width: 10.7rem;
    height: 4.55rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 3rem;
  }

  &__desc {
    font-size: 2rem;
    line-height: 3.1rem;
    text-align: justify;
  }

  &__buttonContainer {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
  }

  &__button {
    width: 15.5rem;
    height: 5.5rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    margin: 0.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .eLearningBanner {
    padding: 3.2rem 2rem 13.4rem 2rem;
    position: relative;

    &__title {
      margin: 0 auto;
      margin-bottom: 2.05rem;
    }

    &__textContainer {
      width: 100%;
      flex-direction: column;
      align-content: center;
      padding: 0;
    }

    &__selectContainer {
      width: 100%;
    }

    &__buttonContainer {
      margin: 0;
      margin-top: 3.2rem;
      flex-wrap: nowrap;
    }

    &__leftImage {
      left: 0.9rem;
      width: 16.9rem;
      height: 10.4rem;
    }

    &__rightImage {
      width: 13.9rem;
      height: 3rem;
      right: 0.2rem;
    }
  }
}
</style>
